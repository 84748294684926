import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link, navigate } from '@reach/router'
import { AppBar, InputAdornment, Grid, Typography, IconButton } from '@material-ui/core'
import { Menu } from '@refera/ui-web'
import { ArrowDown1, User } from '@refera/ui-icons'
import SearchIcon from '@material-ui/icons/Search'
import { useDebounce } from 'use-debounce'
import classnames from 'classnames'

import { getProvidersFiltered } from '_modules/provider/actions'
import { getAgenciesFiltered } from '_modules/agency/actions'
import { getArticles } from '_modules/utils/actions'
import { getGenericParametersSelector } from '_/modules/service-orders/selectors'
import { userSelector } from '_/modules/authentication/selectors'

import Svg, { ICON } from '_components/svg'
import SearchBar from '_components/search-bar'
import Button from '_components/button'

import useHandleInput from '_hooks/use-handle-input'
import useRolePermission from '_hooks/use-role-permission'

import { ROUTES } from '_utils/constants'

import AddPersonIcon from '_assets/icons/ic-add-person.svg'
import AddAgencyIcon from '_assets/icons/ic-house.svg'
import IconLogo from '_assets/icons/ic-refera-re.svg'
import IconFullLogo from '_assets/svgs/refera-logo.svg'

import Notifications from '../notifications'
import Support from '../support'

import HeaderItems from './header-items'
import ModuleSelector from './module-selector'
import useStyles from './styles'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

export const HEADER_THEME = {
  DEFAULT: 'default',
  NO_BUTTONS: 'noButtons',
}

const Header = ({ className, theme }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { checkUserPermission } = useRolePermission()

  const location = useLocation()
  const { pathname } = useLocation()

  const user = useSelector(userSelector)
  const { isFinance, isAdmin, isIntermediary } = useRolePermission()

  const [input, handleInput] = useHandleInput()
  const [searchValue] = useDebounce(input, 1000)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const isLandingPage = location.pathname === ROUTES.LANDING

  const genericParameters = useSelector(getGenericParametersSelector)

  const isNewServiceOrderFormActive = useMemo(
    () => genericParameters?.find(item => item.name === 'new_service_order_form')?.active,
    [genericParameters]
  )

  const agencyIdFromUrl = useMemo(() => {
    const match = pathname.match(/\/imobiliaria\/(\d+)/)
    return match ? parseInt(match[1], 10) : null
  }, [pathname])

  const handleNavigation = useCallback(route => {
    handleClose()
    navigate(route)
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpenTooltip(false)
  }

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }),
    []
  )

  useEffect(() => {
    dispatch(getArticles()).catch(() => {})
  }, [dispatch])

  useEffect(() => {
    if (pathname.includes('prestadores')) {
      dispatch(getProvidersFiltered({ name: searchValue }))
    } else if (pathname.includes('/imobiliarias') || pathname.includes('/gestao-imobiliarias')) {
      dispatch(getAgenciesFiltered({ name: searchValue }))
    } else if (pathname.includes('/imobiliaria')) {
      if (isIntermediary) {
        if (agencyIdFromUrl !== user.agency) {
          navigate('/nao-encontrado')
        }
      }
    }
  }, [dispatch, pathname, searchValue, agencyIdFromUrl, user])

  const renderButton = useMemo(
    () => (
      <Button
        className={styles.add}
        to={
          location.pathname === ROUTES.MANAGE_PROVIDERS
            ? ROUTES.CREATE_PROVIDER
            : ROUTES.CREATE_AGENCY
        }
      >
        <Svg
          icon={location.pathname === ROUTES.MANAGE_PROVIDERS ? AddPersonIcon : AddAgencyIcon}
          className={styles.iconButton}
        />
        {location.pathname === ROUTES.MANAGE_PROVIDERS ? 'Novo prestador' : 'Nova imobiliária'}
      </Button>
    ),
    [location.pathname, styles.add, styles.iconButton]
  )

  const redirectToSetup = useCallback(() => {
    const route = isNewServiceOrderFormActive
      ? `${ROUTES.NEW_SERVICE_LOGGED}/${user?.agencySlug}`
      : `${ROUTES.SETUP_AGENCY_FORM_NO_SLUG}/${user?.agencySlug}`
    window.open(route)
  }, [isNewServiceOrderFormActive, user])

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={classnames(styles.header, styles[theme], className)}
    >
      <Grid className={styles.leftWrapper}>
        {theme !== HEADER_THEME.NO_BUTTONS ? (
          <Grid className={styles.logoContainer}>
            <Link to="/">
              <Svg className={styles.logo} icon={IconLogo} />
            </Link>
          </Grid>
        ) : (
          <Link to="/">
            <Svg className={styles.fullLogo} icon={IconFullLogo} />
          </Link>
        )}
        {(isFinance || isAdmin) && <ModuleSelector className={styles.ModuleSelector} />}
      </Grid>

      {theme !== HEADER_THEME.NO_BUTTONS && (
        <Grid className={styles.container}>
          {(location.pathname === ROUTES.MANAGE_ORDERS ||
            location.pathname === ROUTES.NEW_ORDERS) && (
            <Button
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.SERVICE_ORDER,
                  PERMISSIONS_ACTIONS.ADD,
                  redirectToSetup
                )
              }
              className={styles.add}
            >
              <Svg type={ICON.PLUS} className={styles.iconButton} />
              Novo chamado
            </Button>
          )}

          <Grid className={styles.rightContent}>
            {(location.pathname === ROUTES.MANAGE_AGENCIES ||
              location.pathname === ROUTES.MANAGE_PROVIDERS) && (
              <Grid className={styles.content}>
                <SearchBar
                  className={styles.searchBar}
                  value={input}
                  onChange={handleInput}
                  placeholder={
                    pathname.includes('imobiliarias')
                      ? 'Pesquisar imobiliária'
                      : 'Pesquisar prestador'
                  }
                  InputProps={inputProps}
                />
                {renderButton}
              </Grid>
            )}

            {!isLandingPage && (
              <>
                <Notifications />
                <Support />
              </>
            )}

            <Grid className={styles.userCircle1} disabled>
              <User className={styles.userIcon1} />
            </Grid>
            <Typography className={styles.userLabel1}>{user?.name}</Typography>
            <IconButton
              aria-haspopup="true"
              aria-controls={openTooltip && 'basic-menu'}
              aria-expanded={openTooltip && 'true'}
              onClick={handleClick}
            >
              <ArrowDown1 color="white" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'basic-button', className: styles.menuList }}
              PaperProps={{ className: styles.paper }}
            >
              <HeaderItems handleNavigation={handleNavigation} handleClose={handleClose} />
            </Menu>
          </Grid>
        </Grid>
      )}
    </AppBar>
  )
}

export default React.memo(Header)
